/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";

const links = [
  {
    to: "/",
    label: "fadiazzam.de",
  },
  {
    to: "/contact",
    label: "Contact",
  },
  {
    to: "/imprint",
    label: "Imprint",
  },
];
const Footer = () => {
  return (
    <footer className="z-50 transition-all container ">
      <nav className="py-4 md:py-8">
        <div className="relative p-3 w-full mx-auto drop-shadow-2xl ease-in-out bg-gray-700/20 backdrop-blur rounded-full">
          <div className="flex items-center justify-center">
            {links.map((link) => (
              <div className="md:p-3 p-1">
                <NavLink
                  className="hover:text-primary-400 text-sm flex items-center gap-3"
                  to={link.to}
                >
                  {link.label}
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
