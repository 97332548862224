import React from "react";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  return (
    <div className="container pt-52 h-100">
      <section className="">
        From DashboardLayout
        <Outlet />
      </section>
    </div>
  );
};

export default DashboardLayout;
