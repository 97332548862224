import React, { useState } from "react";
import Button from "../common/Button";

import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const [isLogged, setLogged] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    const enteredName = e.target.name.value;
    const enteredPassword = e.target.password.value;

    const validCredentials =
      enteredName === "fadi" && enteredPassword === "123";

    if (validCredentials) {
      navigate("/dashboard");
      setLogged(true);
    } else {
      setError("Invalid credentials");
    }
  };

  return (
    <div className="container">
      <section className="h-screen flex gap-3 w-full mx-auto flex-col-reverse md:flex-row md:justify-center justify-evenly items-center">
        <form onSubmit={onSubmit}>
          <div className="flex gap-6 mb-6">
            <div>
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your name
              </label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="John"
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                autoComplete="true"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="•••••••••"
                required
              />
            </div>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <Button type="submit" text="Login" onClick={() => {}} />
        </form>
      </section>
    </div>
  );
};

export default Login;
