import { combineReducers } from "redux";

import { SET_FILTER, SET_FILTERED_DATA_LENGTH } from "./actionTypes";

const initialState = "";

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return action.payload;
    default:
      return state;
  }
};

const initialFilterLengthState = 0;

const filteredDataLengthReducer = (
  state = initialFilterLengthState,
  action
) => {
  switch (action.type) {
    case SET_FILTERED_DATA_LENGTH:
      return action.payload;
    default:
      return state;
  }
};

// rootReducer.js
const rootReducer = combineReducers({
  filter: filterReducer,
  filteredDataLength: filteredDataLengthReducer,

  // other reducers...
});

export default rootReducer;
