import React from "react";
import Button from "../components/common/Button";

const NoMatch = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen container text-center">
      <h1 className="text-5xl font-bold mb-4">Oops! Page Not Found</h1>
      <p className="text-lg text-gray-300 mb-8">
        It seems like the page you are looking for doesn't exist.
      </p>

      <Button to="/" text="Go to Home" />
    </div>
  );
};

export default NoMatch;
