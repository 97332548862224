/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaTerminal } from "react-icons/fa";

const Header = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <header className="z-50 transition-all container ">
      <nav className="py-4 md:py-8">
        <div
          className={`
           relative p-3 w-full flex-wrap md:flex-nowrap flex items-center md:items-baseline mx-auto drop-shadow-2xl  ease-in-out bg-gray-700/20 backdrop-blur justify-between  ${
             toggle ? " " : "rounded-full"
           }  
          `}
        >
          <ul className="text-base md:flex md:justify-between md:pt-0 items-center  ">
            <li className="p-3">
              <NavLink
                to={"/"}
                className="hover:text-primary-400 text-sm flex items-center gap-3"
              >
                fadiazzam.de
                <FaTerminal className="text-primary-400 " />
              </NavLink>
            </li>
          </ul>

          <svg
            onClick={() => {
              setToggle(!toggle);
            }}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer md:hidden block hover:text-primary-400 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>

          <div
            className={`${
              toggle
                ? "absolute top-full left-0 right-0 bg-gray-700/20 backdrop-blur"
                : "hidden"
            } w-full md:flex md:items-center md:w-auto`}
          >
            <ul className="text-base md:flex md:justify-between md:pt-0 items-center">
              <li className=" p-3">
                <NavLink
                  className="hover:text-primary-400 text-sm gap-3 flex justify-center items-center "
                  to="/blog"
                >
                  Blog
                </NavLink>
              </li>
              <li className=" p-3">
                <NavLink
                  className="hover:text-primary-400 text-sm gap-3 flex justify-center items-center "
                  to="/treelinks"
                >
                  Treelinks
                </NavLink>
              </li>

              <li className="p-3 ">
                <NavLink
                  className="hover:text-primary-400 text-sm gap-3 flex justify-center "
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
