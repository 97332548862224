import React from 'react';
import TimeLine from './../common/TimeLine';
import SectionHeader from './../common/SectionHeader';
import { workExperienceContent } from '../../constants/index';

const WorkExperience = () => {
  return (
    <div className='py-16'>
      <SectionHeader
        subTitle={'Job Experience'}
        title={
          'A snapshot of my professional journey during and after my studies'
        }
      />

      <article className=''>
        <TimeLine array={workExperienceContent} />
      </article>
    </div>
  );
};

export default WorkExperience;
