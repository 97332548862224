import React from 'react';

const TimeLine = ({ array }) => {
  return (
    <div className='timeLineContainer'>
      <div className='timeLine'>
        <div>
          {array.map((item, i) => {
            return (
              <section key={i} className='year'>
                <div className='leftSide'>
                  <h6>{item.date[0].end}</h6>
                  <h6>{item.date[0].start}</h6>
                </div>
                <section>
                  <h4 className='text-lg md:text-2xl text-primary-400 whitespace-break-spaces'>
                    {item.title}
                  </h4>
                  <h5 className='text-base md:text-lg my-3'>{item.company}</h5>
                  <p className='text-base'>{item?.goal}</p>
                  <p className='text-base'>{item.description}</p>
                  <p className='text-primary-600'>{item.location}</p>
                  <ul className='list-disc'>
                    {item?.tasks?.length > 0 && (
                      <li className='list-none my-3'>
                        <strong>Responsibilities</strong>
                      </li>
                    )}
                    {item?.tasks?.map((task) => {
                      return (
                        <li className='list-inside' key={task}>
                          {task}
                        </li>
                      );
                    })}
                  </ul>
                </section>
              </section>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TimeLine;
