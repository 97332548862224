import React, { useState, useEffect } from "react";

import DashboardHeader from "../common/DashboardHeader";
import DashboardInfo from "../common/DashboardInfo";
import DashboardTable from "../common/DashboardTable";

import { getAllPosts } from "../../controllers/dashboardController";

const Dashboard = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const posts = await getAllPosts();
        setBlogPosts(posts);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <DashboardHeader />
      <DashboardInfo />
      <DashboardTable articles={blogPosts} />
    </div>
  );
};

export default Dashboard;
