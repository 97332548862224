// Function to calculate read time based on content length
const calculateReadTime = (content) => {
  // Assuming an average reading speed of 200 words per minute
  const wordsPerMinute = 50;

  // Calculate the total number of words in the content
  const totalWords = content.reduce((total, element) => {
    if (typeof element.props.children === "string") {
      // Count words in paragraphs
      total += element.props.children.split(/\s+/).length;
    }
    return total;
  }, 0);

  // Calculate the estimated read time in minutes
  const readTime = Math.ceil(totalWords / wordsPerMinute);

  return readTime;
};

module.exports = calculateReadTime;
