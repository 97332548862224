import { SET_FILTER, SET_FILTERED_DATA_LENGTH } from "./actionTypes";

export const setFilter = (selectedTag) => {
  return {
    type: SET_FILTER,
    payload: selectedTag,
  };
};

export const setFilteredDataLength = (length) => {
  return {
    type: SET_FILTERED_DATA_LENGTH,
    payload: length,
  };
};
