import React from "react";

import ProfileHero from "./ProfileHero";
import ProfileDescription from "./ProfileDescription";
import ProfileArticles from "./ProfileArticles";
import ProfileYearsArticles from "./ProfileYearsArticles";

function Profile() {
  return (
    <div className="pb-6">
      <ProfileHero sliderText="THE BLOG" />
      <div className="container flex flex-col md:flex-row gap-6 ">
        <div className="flex flex-col gap-6 flex-shrink grow-0">
          <ProfileDescription />
          <ProfileYearsArticles />
        </div>

        <ProfileArticles />
      </div>
    </div>
  );
}

export default Profile;
