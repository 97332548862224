import React from "react";
import { BrowserRouter } from "react-router-dom";

import Header from "./Layout/navigation/Header";
import Footer from "./Layout/navigation/Footer";
import AppRouter from "./Layout/navigation/Routes";

function App() {
  return (
    <BrowserRouter>
      <div className="scroll-smooth h-full flex flex-col z-10 relative">
        {!window.location.pathname.includes("/treelinks") && <Header />}
        <div className="grow">
          <AppRouter />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
