import React from "react";
import blogPosts from "../Blog/blogPosts";
import { useNavigate } from "react-router-dom";

const ProfileYearsArticles = () => {
  let navigate = useNavigate();

  const groupBlogPostsByYear = require("../utils/groupBlogPostsByYear");
  const blogPostsByYear = groupBlogPostsByYear({ blogPosts });

  return (
    <div className="drop-shadow-2xl backdrop-blur word-break-word flex flex-col gap-4  p-3 bg-gray-700/20 rounded-lg">
      {Object.keys(blogPostsByYear).map((year) => (
        <div key={year} className="flex flex-col gap-4 ">
          <h3 className="text-lg">{year}</h3>

          <div className="flex gap-4 flex-wrap">
            {blogPostsByYear[year].map((post) => (
              <div
                key={post.id}
                className="drop-shadow-2xl backdrop-blur p-3 bg-gray-700/20 rounded-lg cursor-pointer hover:bg-primary-500/50 w-full"
                onClick={() => navigate(`/blog/${post.slug}`)}
              >
                <p>{post.title}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProfileYearsArticles;
