import React, { useState } from "react";
import { motion } from "framer-motion";
import { showMenu } from "../../utils/motion";

import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../redux/actions";

const Dropdown = ({ options, label, icon }) => {
  const [shown, setShown] = useState(false);
  const [selected, setSelected] = useState("");

  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);

  const handleTagChange = (event) => {
    dispatch(setFilter(event));
    setSelected(event);
  };

  return (
    <div className="flex gap-3">
      <div className="flex items-center justify-center gap-1  text-sm cursor-pointer ">
        <span>{label}</span>
        {icon}
      </div>

      <motion.div
        className="text-sm cursor-pointer hover:text-primary-400 hover:border-primary-400 border border-gray-600 rounded-lg px-3 py-1"
        onClick={() => setShown(true)}
        onHoverEnd={() => setShown(false)}
      >
        {filter === "" ? "select" : filter}

        <motion.ul
          variants={showMenu}
          initial="exit"
          animate={shown ? "enter" : "exit"}
          className="absolute bg-gray-800/95 backdrop-blur drop-shadow-2xl rounded-lg p-2 z-50 whitespace-nowrap"
        >
          {options.map((option, i) => {
            return (
              <motion.li
                key={i}
                whileHover={{
                  x: 2,
                }}
                className={`cursor-pointer p-1 flex items-center gap-2 justify-between ${
                  selected === option.category &&
                  "text-green-400 hover:text-green-200 "
                }`}
                onClick={() =>
                  handleTagChange(
                    option.category === selected ? "" : option.category
                  )
                }
              >
                <span>{option.category}</span>
                <span>{option.categoryIcon}</span>
              </motion.li>
            );
          })}
        </motion.ul>
      </motion.div>
    </div>
  );
};

export default Dropdown;
