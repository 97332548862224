import React, { useState, useEffect } from 'react';
import SectionHeader from '../../components/common/SectionHeader';
import { AboutContent } from '../../constants/index';

const About = () => {
  const [offset, setOffset] = useState(0);

  const handleScroll = () => {
    setOffset(window.pageYOffset);
  };

  useEffect(() => {
    const parallaxElements = document.querySelectorAll('.parallax');
    parallaxElements.forEach((el) => {
      const randomY = Math.floor(Math.random() * 100);
      el.style.transform = `translate3d(0px, -${
        (offset + randomY) / 10
      }px, 0px)`;
    });
  }, [offset]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='py-16'>
      <SectionHeader
        subTitle={'about me'}
        title={
          "Hey there! I'm Fadi, a creative web developer based in Dresden."
        }
      />

      <div className='flex gap-3 flex-col items-center md:flex-row'>
        <div className='flex items-center justify-center md:w-3/6'>
          <div className='avatar avatar-w-h-300' title='Picture of Fadi Azzam'></div>
        </div>
        <div className='md:w-3/6'>{AboutContent[0].text}</div>
      </div>
    </div>
  );
};

export default About;
