import React from "react";

const Contact = () => {
  return (
    <div className="flex justify-center items-center  h-screen">
      <div className="shadow-xl cursor-pointer rounded-lg border border-white/10 py-6 px-5 backdrop-blur-[60px] md:p-8 lg:p-4 xl:p-8 ">
        <div className="flex flex-col h-full ">
          <div className="">Contact</div>
          <div className="grow">
            <a href="mailto:fadi.azzam@outlook.de" className="text-primary-400">
              fadi.azzam@outlook.de
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
