import React from "react";
import { generateUUID } from "three/src/math/MathUtils";
import SocialMedia from "../components/ui/SocialMedia";
import ProfileHero from "../Profile/ProfileHero";
import SectionHeader, {
  SectionTitle,
} from "../components/common/SectionHeader";

const linkstree = [
  {
    id: generateUUID(),
    label: "Trade Republic - تطبيق شراء الأسهم",
    link: "https://ref.trade.re/md86tnkq",
    img: "",
  },
  {
    id: generateUUID(),
    label: "Binance - تطبيق شراء العملات الرقمية",
    link: "https://www.binance.info/en/activity/referral-entry/CPA?ref=CPA_00PDDENATK",
    img: "",
  },
];

const links = linkstree.map((link) => {
  return (
    <div key={link.id}>
      <a
        href={link.link}
        target="_blank"
        rel="noreferrer"
        className="treeLinkButton"
      >
        {link.label}
      </a>
    </div>
  );
});

const Treelinks = () => {
  return (
    <div className="pb-16">
      <ProfileHero sliderText="دليلك الشامل للمعلومات" btn={false} />
      <SectionTitle
        subTitle={`عدد الروابط ${linkstree.length}`}
        title="روابط التحميل المباشرة"
        inArabic={true}
        isSubHeader={true}
      />
      <div className="container flex flex-col">
        <div className="drop-shadow-2xl backdrop-blur py-10 bg-gray-700/20 rounded-lg">
          <div className="flex flex-col items-center gap-6">
            <div className="flex flex-col gap-2">{links}</div>
            <div className="flex gap-4">
              <SocialMedia />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Treelinks;
