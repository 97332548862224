import React from "react";
import blogPosts from "../Blog/blogPosts";
import Dropdown from "../components/common/Dropdown";
import { CiFilter } from "react-icons/ci";

import { useSelector } from "react-redux";

function removeDuplicates(array) {
  return array.filter((item, index, self) => {
    // Convert each item to a string for comparison
    const itemString = JSON.stringify(item);

    // Check if the item is the first occurrence in the array
    return (
      index ===
      self.findIndex((obj) => {
        return JSON.stringify(obj) === itemString;
      })
    );
  });
}

const categories = removeDuplicates([
  ...new Set(
    blogPosts.map((post) => {
      return { category: post.category, categoryIcon: post.categoryIcon };
    })
  ),
]);

const ProfileFilter = () => {
  const blogPostsLength = useSelector((state) => state.filteredDataLength);

  return (
    <div className="drop-shadow-2xl bg-gray-700/20 p-3 flex justify-between items-center z-10 rounded-lg">
      <h1 className="text-sm text-white">{blogPostsLength} Posts</h1>
      <Dropdown options={categories} label={"Filter by"} icon={<CiFilter />} />
    </div>
  );
};

export default ProfileFilter;
