import React from "react";
import { BsCalendar4Week } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { FcApproval } from "react-icons/fc";
import { AiOutlineUser } from "react-icons/ai";
import Button from "../components/common/Button";
import ProfileImage from "../components/common/ProfileImage";

const ProfileHero = ({ sliderText, btn = true }) => {
  return (
    <div>
      <div className="w-full bg-primary-500 h-72 flex justify-center items-center">
        <h1 className="text-3xl md:text-5xl font-extrabold leading-none tracking-tight  text-background font-arabic">
          {sliderText}
        </h1>
      </div>
      <div className="container">
        <div className="flex items-center gap-3 flex-col md:flex-row">
          <div className="w-36 md:h-36 h-16 relative">
            <div className="absolute md:bottom-8 bottom-0">
              <ProfileImage size={"big"} />
            </div>
          </div>
          <div className="flex md:items-center justify-between flex-1 w-full flex-col md:flex-row pb-3">
            <div className="font-medium dark:text-white py-3 md:py-7 ">
              <div className="flex items-center gap-3">
                <h1 className="text-2xl text-white">Fadi Azzam</h1>
                <FcApproval className="text-lg text-green-400" />
              </div>
              <p className="text-sm py-1">Software Developer</p>
              <div className="flex gap-3">
                <div className="flex items-center justify-center gap-2">
                  <GoLocation className="text-gray-400/80 text-sm font-extralight" />
                  <p className="text-sm text-gray-400/80 font-extralight">
                    Dresden
                  </p>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <BsCalendar4Week className="text-gray-400/80 text-sm font-extralight" />
                  <p className="text-sm text-gray-400/80 font-extralight">
                    Since 2011
                  </p>
                </div>
              </div>
            </div>
            {btn && (
              <Button
                to="/contact"
                text="contact me"
                type="btn-sm"
                icon={<AiOutlineUser className="w-5 h-5 ml-2" />}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHero;
