import React from "react";

const SectionHeader = ({ subTitle, title }) => {
  return (
    <div className={`flex flex-col gap-3 pb-10 `}>
      <div className="w-fit">
        <span className="text-primary-400 uppercase">{subTitle}</span>
      </div>
      <h2 className={`text-3xl font-extrabold `}>{title}</h2>
    </div>
  );
};

export const SectionTitle = ({ subTitle, title, inArabic, isSubHeader }) => {
  return (
    <div className={`flex flex-col gap-3 py-16 text-center`}>
      <div className="">
        <span
          className={`text-primary-400 uppercase ${
            inArabic ? "font-arabic" : ""
          }`}
        >
          {subTitle}
        </span>
      </div>
      <h2
        className={`${isSubHeader ? "text-3xl" : "text-6xl"} font-extrabold ${
          inArabic ? "font-arabic" : ""
        }`}
      >
        {title}
      </h2>
    </div>
  );
};

export default SectionHeader;
