import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { deletePost } from "../../controllers/dashboardController";

const DeletePost = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDelete = async () => {
    console.log(`Deleting post with id: ${id}`);
    try {
      await deletePost({ id });
      console.log(`Error delete this post with id: ${id}`);
      navigate("/dashboard");
    } catch (error) {
      console.error(`Error delete this post with id: ${id}`);
    }
  };

  return (
    <div>
      <h2>Delete Post</h2>
      <p>Are you sure you want to delete the post with ID {id}?</p>
      <button onClick={handleDelete}>Delete Post</button>
    </div>
  );
};

export default DeletePost;
