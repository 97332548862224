import React from "react";
import Button from "../../components/common/Button";

let fields = ["title", "author", "category_name", "Action"];

const TableRow = ({ article }) => {
  let trArr = [];
  for (const [key, value] of Object.entries(article)) {
    if (fields.includes(key)) {
      trArr.push(<TableCell key={key} value={value} />);
    }
  }

  trArr.push(<TableCell key="action" btn={true} postId={article.id} />);

  return (
    <tr
      key={article.id}
      className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-primary-900/50 border-b dark:border-primary-800"
    >
      {trArr}
    </tr>
  );
};

const TableCell = ({ value, btn = false, postId }) => {
  if (btn) {
    return (
      <td className="px-6 py-4">
        <Button to={`delete-post/${postId}`} text="delete" />
      </td>
    );
  }
  return <td className="px-6 py-4">{value}</td>;
};

const TableHead = ({ fields }) => {
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-primary-400 dark:text-gray-800">
      <tr>
        {fields.map((th) => (
          <th key={th} className="px-6 py-3">
            {th}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const DashboardTable = ({ articles }) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200">
        <TableHead fields={fields} />
        <tbody>
          {articles.map((article) => (
            <TableRow key={article.id} article={article} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardTable;
