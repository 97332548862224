import React from "react";

const ProfileImage = ({ size }) => {
  switch (size) {
    case "big":
      return (
        <img
          className="w-36 h-36 rounded-full object-cover object-center border-4  bg-background"
          src={require("../../assets/imgs/me.png")}
          alt="Fadi azzam"
        />
      );
    default:
      return (
        <img
          className="w-14 h-14 rounded-full object-cover object-center  border-gray-700 border"
          src={require("../../assets/imgs/me.png")}
          alt="Fadi azzam"
        />
      );
  }
};

export default ProfileImage;
