import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Button = ({ to = "/", onClick = "", type, icon = "", text = "" }) => {
  const [hovered, setHovered] = useState(false);

  const [btnStyle, setBtnStyle] = useState("");
  useEffect(() => {
    switch (type) {
      case "Outline":
        setBtnStyle(
          " font-medium text-gray-900 border text-sm px-3 py-2 border-gray-300 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:border-primary-400 dark:focus:ring-gray-800"
        );
        break;

      case "none":
        setBtnStyle(
          "text-primary-400 text-sm py-1 px-2 flex gap-1 items-center cursor-pointer "
        );
        break;
      case "btn-sm":
        setBtnStyle(
          "  font-medium text-gray-900 border text-sm px-4 py-2.5 border-transparent  bg-primary-400 hover:bg-primary-300  focus:ring-primary-300 dark:focus:ring-primary-900"
        );
        break;
      default:
        setBtnStyle(
          " font-medium text-gray-900 border text-sm px-3 py-2 border-transparent  bg-primary-400 hover:bg-primary-300  focus:ring-primary-300 dark:focus:ring-primary-900"
        );
    }
  }, [type]);

  if (type === "submit") {
    return (
      <input
        type="submit"
        value={text}
        className={`cursor-pointer inline-flex items-center justify-center text-center ${btnStyle}`}
        onClick={onClick}
      />
    );
  }

  if (type === "add") {
    return (
      <button
        className={`inline-flex items-center justify-center text-center gap-3 ${btnStyle}`}
        onClick={onClick}
      >
        {text}
        <span
          className={`transition-transform duration-300 ${
            hovered && "animate-bounce"
          }`}
        >
          {icon}
        </span>
      </button>
    );
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`inline-flex items-center justify-center text-center ${btnStyle}`}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      {text}
      <span
        className={`transition-transform duration-300 ${
          hovered && "animate-bounce"
        }`}
      >
        {icon}
      </span>
    </Link>
  );
};

export default Button;
