import React from "react";

const DashboardCard = ({ count, text, icon }) => {
  return (
    <div className="flex-auto basis-48 ">
      <p className="text-sm">{text}</p>

      <div className="p-3 bg-primary-400 dark:text-gray-800 rounded-md shadow-md">
        <div className="flex items-center justify-between">
          <span className="text-xl">{icon}</span>
          <div className="flex justify-center items-center flex-col">
            <h5 className="text-4xl font-bold">{count}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
