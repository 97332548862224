import React from 'react';
import TimeLine from '../../components/common/TimeLine';
import SectionHeader from '../../components/common/SectionHeader';
import { hobbiesContent } from '../../constants/index';

const hobbies = () => {
  return (
    <div className='py-16'>
      <SectionHeader
        subTitle={'HOBBIES'}
        title={'Volunteer Work as a Coordinator for an Educational Program'}
      />

      <article className=''>
        <TimeLine array={hobbiesContent} />
      </article>
    </div>
  );
};
export default hobbies;
