import axios from "axios";

// category controller
export const getAllCategory = async () => {
  try {
    const response = await axios.get(
      `http://fadiazzam.de/api/category/read.php`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const getCategoryById = async (id) => {
  try {
    const response = await axios.get(
      `http://fadiazzam.de/api/category/read_single.php?id=${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

// Post controller
export const addNewPost = async (data) => {
  try {
    const response = await axios.post(
      "https://fadiazzam.de/api/post/create.php",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle the error or return a default value
    return [];
  }
};

export const getAllPosts = async () => {
  try {
    const response = await axios.get("https://fadiazzam.de/api/post/read.php");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle the error or return a default value
    return [];
  }
};

export const deletePost = async (data) => {
  try {
    const response = await axios.delete(
      "https://fadiazzam.de/api/post/delete.php",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle the error or return a default value
    return [];
  }
};

export const getTotalCount = async () => {
  try {
    const response = await axios.get("https://fadiazzam.de/api/post/read.php");
    return response.data.length;
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle the error or return a default value
    return 0;
  }
};

// dashboard info controller
export const getTotalViews = () => {
  // Implement logic to calculate total views
  return 5;
};

export const getTotalComments = () => {
  // Implement logic to calculate total comments
  return 5;
};

export const getDraftCount = () => {
  // Implement logic to calculate draft count
  return 5;
};

export const getDeletedCount = () => {
  // Implement logic to calculate deleted count
  return 5;
};
