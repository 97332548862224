import React from "react";
import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = ({ item }) => {
  return (
    <motion.div
      whileHover={{
        y: -5,
      }}
      transition={{
        duration: 1,
      }}
      className="shadow-xl cursor-pointer rounded-lg border border-white/10 py-6 px-5 backdrop-blur-[60px] md:p-8 lg:p-4 xl:p-8 relative overflow-hidden h-full"
    >
      <div className="flex h-full gap-3">
        <div className="grow">
          <h5 className="text-xl text-primary-400">{item.title}</h5>
        </div>
      </div>
    </motion.div>
  );
};

export default Card;
