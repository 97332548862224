import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";

import {
  addNewPost,
  getAllCategory,
} from "../../controllers/dashboardController";

const CreatePost = () => {
  const navigate = useNavigate();

  const [categorys, setCategorys] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const getCategory = async () => {
      const categorys = await getAllCategory();
      setCategorys(categorys);
    };
    getCategory();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const title = e.target.title.value;
    const body = e.target.body.value;
    const author = e.target.author.value;
    const category = e.target.category.value;

    // Get the selected category and find its ID
    const selectedCategoryId = categorys.data.find(
      (cat) => cat.name === category
    )?.id;

    const postData = { title, body, author, category_id: selectedCategoryId };

    try {
      await addNewPost(postData);
      navigate("/dashboard");
    } catch (error) {
      setError("Error adding new post");
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="space-y-6">
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your Title
            </label>
            <input
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="your title"
              required
            />
          </div>
          <div>
            <label
              htmlFor="author"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your name
            </label>
            <input
              type="text"
              id="author"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="your name"
              required
            />
          </div>
          <div>
            <label
              htmlFor="body"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Tell your ideas...
            </label>
            <textarea
              id="body"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="your space"
            ></textarea>
          </div>
          <div>
            <label
              htmlFor="category"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Select your category
            </label>
            <select
              id="category"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {categorys?.data?.map((cat) => (
                <option key={cat.id} aria-label={cat.id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <Button type="submit" text="Login" onClick={() => {}} />
        </div>
      </form>
    </div>
  );
};

export default CreatePost;
