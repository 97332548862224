import React from 'react'
import { socialLinks } from '../../constants'
import { motion } from 'framer-motion';

const SocialMedia = () => {
  return (
    socialLinks.map((socialLink, i) => {
        return (
          <motion.div
            key={i}
            whileHover={{
              y: -3,
            }}
          >
            <a
              className=' text-primary-400 hover:text-primary-300 text-xl'
              target='_blank'
              rel='noreferrer'
              href={socialLink.link}
              aria-label={socialLink.label}
            >
              {socialLink.icon}
            </a>
          </motion.div>
        );
      })
  )
}

export default SocialMedia