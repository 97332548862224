import React from "react";
import { useParams, Link } from "react-router-dom";
import blogPosts from "./blogPosts";
import BlogPostsContainer from "./BlogPostsContainer";

const BlogCategory = () => {
  const { category } = useParams();

  const categoryPosts = blogPosts.filter((post) => post.category === category);

  return (
    <div className="container py-36 h-screen">
      <div className="d-flex justify-content-center my-5">
        <h3>{category}</h3>
      </div>
      <BlogPostsContainer blogPosts={categoryPosts} />

      <p className="my-6">
        <Link
          className="text-primary-400 border-primary-400 border py-1 px-3 hover:border-primary-300 transition-all inline-block"
          to="/blog"
        >
          Back to Blog
        </Link>
      </p>
    </div>
  );
};

export default BlogCategory;
