import React from "react";
import { useRoutes } from "react-router-dom";

import LandingPage from "../../Pages/LandingPage";
import Contact from "../../Pages/Contact";
import Imprint from "../../Pages/Imprint";
import NoMatch from "../../Pages/NoMatch";
import Treelinks from "../../Pages/Treelinks";

import Profile from "../../Profile/Profile";
import BlogCategory from "../../Blog/BlogCategory";
import BlogPost from "../../Blog/BlogPost";

import Login from "../../components/ui/Login";
import DashboardLayout from "../../Admin/ui/DashboardLayout";
import Dashboard from "../../Admin/ui/Dashboard";
import CreatePost from "../../Admin/ui/CreatePost";
import UpdatePost from "../../Admin/ui/UpdatePost";
import DeletePost from "../../Admin/ui/DeletePost";

export default function AppRouter() {
  let element = useRoutes([
    {
      path: "/",
      element: <LandingPage />,
      header: true,
      footer: true,
    },
    {
      path: "/treelinks",
      element: <Treelinks />,
      header: false,
      footer: false,
    },
    {
      path: "/imprint",
      element: <Imprint />,
      header: true,
      footer: true,
    },
    {
      path: "/contact",
      element: <Contact />,
      header: true,
      footer: true,
    },
    {
      path: "/blog",
      element: <Profile />,
      header: true,
      footer: true,
    },
    {
      path: "/blog/category/:category",
      element: <BlogCategory />,
      header: true,
      footer: true,
    },
    {
      path: "/blog/:slug",
      element: <BlogPost />,
      header: true,
      footer: true,
    },
    {
      path: "/login",
      element: <Login />,
      header: true,
      footer: true,
    },

    {
      path: "/dashboard",
      element: <DashboardLayout />,
      header: true,
      footer: true,
      children: [
        { path: "", element: <Dashboard />, header: true, footer: true },
        {
          path: "new-post",
          element: <CreatePost />,
          header: true,
          footer: true,
        },
        {
          path: "edit-post",
          element: <UpdatePost />,
          header: true,
          footer: true,
        },
        {
          path: "delete-post/:id",
          element: <DeletePost />,
          header: true,
          footer: true,
        },
      ],
    },
    {
      path: "*",
      element: <NoMatch />,
      header: true,
      footer: true,
    },
  ]);
  return element;
}
