import React from 'react';
import TimeLine from '../../components/common/TimeLine';
import SectionHeader from '../../components/common/SectionHeader';
import { educationContent } from '../../constants/index';

const Education = () => {
  return (
    <div className='py-16'>
      <SectionHeader
        subTitle={'Education Quality'}
        title={'Academic Path in Germany and in My Homeland, Syria'}
      />
      <article className=''>
        <TimeLine array={educationContent} />
      </article>
    </div>
  );
};

export default Education;
