import React, { useEffect, useState } from "react";
import DashboardCard from "../common/DashboardCard";
import { FaListUl } from "react-icons/fa";
import { BsEye } from "react-icons/bs";
import { BsPencil } from "react-icons/bs";
import { BsTrash } from "react-icons/bs";

import { FaPencilRuler } from "react-icons/fa";

import {
  getTotalCount,
  getTotalViews,
  getTotalComments,
  getDraftCount,
  getDeletedCount,
} from "../../controllers/dashboardController";

export default function DashboardInfo() {
  const [totalCount, setTotalCount] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const count = await getTotalCount();
        setTotalCount(count);
      } catch (error) {
        console.error("Error fetching total count:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="flex gap-6 flex-wrap">
      <DashboardCard
        count={totalCount !== null ? totalCount : "Loading..."}
        text="Total count"
        icon={<FaListUl />}
      />

      <DashboardCard
        count={getTotalViews()}
        text="Total Views"
        icon={<BsEye />}
      />

      <DashboardCard
        count={getTotalComments()}
        text="Total Comments"
        icon={<BsPencil />}
      />

      <DashboardCard
        count={getDraftCount()}
        text="Drafts"
        icon={<FaPencilRuler />}
      />

      <DashboardCard
        count={getDeletedCount()}
        text="Deleted Articles"
        icon={<BsTrash />}
      />
    </div>
  );
}
