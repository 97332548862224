import React, { useEffect } from "react";

import ProfileFilter from "./ProfileFilter";
import blogPosts from "../Blog/blogPosts";

import Article from "./Article";

import { useDispatch, useSelector } from "react-redux";
import { setFilteredDataLength } from "../redux/actions";

const ProfileArticles = () => {
  const dispatch = useDispatch();

  const filter = useSelector((state) => state.filter);
  const filteredArticles = filter
    ? blogPosts.filter((article) => article.category.includes(filter))
    : blogPosts;

  useEffect(() => {
    dispatch(setFilteredDataLength(filteredArticles.length));
  }, [dispatch, filteredArticles.length]);

  return (
    <div className="flex flex-col gap-6 shrink-0">
      <ProfileFilter />
      <div className="flex flex-col gap-6 ">
        {filteredArticles.map((post) => {
          return <Article key={post.id} post={post} />;
        })}
      </div>
    </div>
  );
};

export default ProfileArticles;
