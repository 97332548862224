import React from "react";

const TreeView = ({ treeData = [] }) => {
  return (
    <ul className="flex-auto ">
      {treeData.map((item, i) =>
        item.name || item.label ? (
          <li key={i}>
            <div
              className={`flex ${
                item.label && "pb-3 gap-2 items-center"
              }  justify-start gap-1 cursor-pointer hover:text-primary-400`}
            >
              <span
                className={`${
                  item.label ? "text-sm md:text-2xl text-primary-400" : ""
                } `}
              >
                {item.icon}
              </span>

              <span className="text-sm md:text-xl text-primary-400">
                {item.label}
              </span>
              {item.name && <span className="text-sm ">{item.name}</span>}
            </div>

            {item.children && (
              <TreeView key={item.name} treeData={item.children} />
            )}
          </li>
        ) : null
      )}
    </ul>
  );
};

const SkillsTree = ({ data }) => {
  return (
    <div className="skillsTreeView pb-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {data.map((e, index) => {
        return (
          <div key={index} className="relative overflow-hidden ">
            <div className="absolute w-12 h-12 backdrop-blur-3xl rounded-full bg-primary-300 left-1 bottom-1 animate-movePosition"></div>

            <div className="shadow-xl cursor-pointer rounded-lg border border-white/10 py-6 px-5 backdrop-blur-[60px] md:p-8 lg:p-4 xl:p-8 relative overflow-hidden h-full">
              <TreeView treeData={e} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SkillsTree;
