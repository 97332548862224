import React from "react";
import { RiChatSmile3Line } from "react-icons/ri";

import SocialMedia from "./SocialMedia";
import Button from "../common/Button";

const Hero = () => {
  return (
    <section className="h-40rem flex gap-3 w-full mx-auto flex-col-reverse md:flex-row md:justify-center justify-evenly items-center">
      <div className="flex md:flex-col gap-6 ">
        <SocialMedia />
      </div>
      <div className="flex-auto flex justify-center items-center">
        <div className="flex items-start flex-col gap-4 md:w-3/5">
          <p className="text-justify  text-align-justify ">Hey, i'm</p>
          <h1 className="text-3xl md:text-7xl font-extrabold tracking-tight leading-none inline-block">
            Fadi Azzam <span className="text-primary-400">,</span>
          </h1>
          <p className=" text-justify  text-align-justify ">
            a Software Engineer based in Dresden - Germany.
          </p>
          <div className="flex gap-3 w-auto ">
            <Button
              text="contact me"
              to="contact"
              icon={<RiChatSmile3Line className="text-base ml-2" />}
            />
            <Button to="blog" text="My Blog" type="Outline" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
