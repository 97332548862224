import React from 'react';
import Card from '../../components/common/Card';
import SectionHeader from '../../components/common/SectionHeader';
import { skillsContent, skillsHierarchyContent } from '../../constants/index';
import SkillsTree from '../common/skillsTree';
const Skills = () => {
  return (
    <div className='py-16'>
      <SectionHeader
        subTitle={'my skills'}
        title={
          "Here are the programming, markup, and scripting languages I've acquired through my studies and work experience."
        }
      />

      <SkillsTree data={skillsHierarchyContent} />

      {/* <article className="box-border max-w-7xl sm:columns-1 md:columns-2 lg:columns-3 xl:columns-3">
        {skillsContent.map((item, i) => {
          return <Card key={i} item={item} />;
        })}
      </article> */}
    </div>
  );
};

export default Skills;
