import React from "react";
import { BsPlusLg } from "react-icons/bs";
import Button from "../../components/common/Button";

const DashboardHeader = () => {
  return (
    <div className="flex justify-between items-center border-b border-gray-700 py-3">
      <h1 className="text-2xl">Dashboard</h1>
      <Button to="new-post" text="Add new Article" icon={<BsPlusLg />} />
    </div>
  );
};

export default DashboardHeader;
