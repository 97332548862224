import React from "react";
import { useNavigate } from "react-router-dom";

import { GiEarthAmerica } from "react-icons/gi";
import ProfileImage from "../components/common/ProfileImage";

const Article = ({ post }) => {
  const calculateReadTime = require("../utils/calculateReadTime");

  let navigate = useNavigate();

  return (
    <div
      className="drop-shadow-2xl backdrop-blur grid grid-rows-auto/1fr grid-cols-auto/1fr/1fr gap-4 cursor-pointer  word-break-word  p-3 bg-gray-700/20 rounded-lg"
      onClick={() => navigate(`/blog/${post.slug}`)}
    >
      <div className=" row-span-2">
        <ProfileImage />
      </div>

      <div className="col-span-2 pt-2 flex justify-between">
        <div className="flex flex-col gap-1 items-start">
          <h1 className="text-white leading-none">{post.author}</h1>
          <div className="flex justify-center items-start gap-1">
            <span className="text-xs text-gray-400">{post.date}</span>
            <GiEarthAmerica className=" text-sm text-gray-400" />
          </div>
        </div>
        <div>
          <span
            className="rounded-full border border-primary-400 px-5 text-primary-400 text-xs py-1 text-center cursor-pointer hover:border-r-8 transition-all "
            onClick={() => navigate(`/blog/category/${post.category}`)}
          >
            {post.category}
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-3 col-span-2 ">
        <div className="space-y-2 pb-3 ">
          <h3 className="text-lg font-semibold group-hover:underline group-focus:underline">
            {post.title}
          </h3>
        </div>
        <img
          className="object-cover w-full h-44 dark:bg-gray-500 rounded-lg"
          src={post.image}
          alt={post.title}
        />
        <div className="flex justify-between items-center">
          <span className="text-xs">
            {calculateReadTime(post.content)} min read
          </span>
        </div>
      </div>
    </div>
  );
};

export default Article;
