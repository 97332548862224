import React from "react";
import { useNavigate } from "react-router-dom";

function BlogPostsContainer({ blogPosts }) {
  let navigate = useNavigate();

  return (
    <section className=" mt-3">
      <div className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {blogPosts.map((post, index) => (
          <div
            className="max-w-sm mx-auto drop-shadow-2xl backdrop-blur flex flex-col cursor-pointer word-break-word border border-gray-50/10"
            key={index}
            onClick={() => navigate(`/blog/${post.slug}`)}
          >
            <img
              className="object-cover w-full  h-44 dark:bg-gray-500"
              src={post.image}
              alt={post.title}
            />
            <div className="p-6 flex flex-col grow">
              <div className="space-y-2 pb-3 grow">
                <h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">
                  {post.title}
                </h3>
                <span className="text-xs dark:text-gray-400">{post.date}</span>
                <p>{post.author}</p>
              </div>
              <div>
                <span
                  className="post-category pointer hover:bg-primary-200"
                  onClick={() => navigate(`/blog/category/${post.category}`)}
                >
                  {post.category}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default BlogPostsContainer;
