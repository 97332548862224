const groupBlogPostsByYear = ({ blogPosts }) => {
  const blogPostsByYear = {};

  blogPosts.forEach((post) => {
    const year = post.date.split("-")[0];

    if (!blogPostsByYear[year]) {
      blogPostsByYear[year] = [];
    }

    blogPostsByYear[year].push(post);
  });

  return blogPostsByYear;
};

module.exports = groupBlogPostsByYear;
