import React, { useState } from 'react';

export default function Tabs({ data }) {
  const [visibleTab, setVisibleTab] = useState(data[0].id);

  const listTitles = data.map((item) => (
    <li
      key={item.id}
      onClick={() => setVisibleTab(item.id)}
      className={`cursor-pointer p-3 transition-colors border-b-2  hover:bg-gray-700/20  font-semibold flex justify-center items-center text-sm break-all h-full md:text-lg ${
        visibleTab === item.id
          ? 'border-b-2 border-primary-400 text-primary-400  bg-gray-700/20'
          : 'border-transparent'
      }`}
    >
      {item.tabTitle}
    </li>
  ));

  const listContent = data.map((item) => (
    <div
      key={item.id}
      style={visibleTab === item.id ? {} : { display: 'none' }}
    >
      {item.tabContent}
    </div>
  ));

  return (
    <div className=''>
      <ul className='grid grid-flow-col auto-cols-fr items-center border-b border-gray-200 dark:border-gray-700'>
        {listTitles}
      </ul>
      <div className='tab-content'>{listContent}</div>
    </div>
  );
}
